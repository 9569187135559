import * as React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/utils/SEO"
import { MockUps } from "../components/pages/mock-ups/MockUps"

const MockUpsPage = () => (
  <Layout>
    <SEO title="Sticker Mock Ups" />
    <MockUps/>
  </Layout>
)

export default MockUpsPage
