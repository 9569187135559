import * as React from 'react';

export interface IMockUpsProps {
}

export function MockUps (props: IMockUpsProps) {
  return (
    <div>
      <p>Mock up page coming soon :)</p>
    </div>
  );
}
